import { PathRoutes } from 'src/app/routes/path-routes';
import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/helpers/helper.service';
import { Router, ActivatedRoute } from '@angular/router';
import { PackagesService } from 'src/app/services/packages.service';

@Component({
  selector: 'app-packages-detail',
  templateUrl: './packages-detail.component.html',
  styleUrls: ['./packages-detail.component.scss']
})
export class PackagesDetailComponent implements OnInit {
  environment = environment
  PathRoutes = PathRoutes
  item: any
  id = null
  
  constructor(
    public helper: HelperService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private packagesService: PackagesService
  ) { }

  ngOnInit() {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.getItem(this.id)
  }

  getItem(id) {
    this.packagesService.show(id).then((res: any) => {
      if(res.status){
        this.item = res.data
      }
    }).catch(e => console.log(e))
  }

  delete(){
    this.helper.deleteSwal().then((result) => {
      if(result.value){
        this.packagesService.delete(this.id).then((res:any) => {
          if(res.status){
            this.helper.triggerNotification(true, 'Excluído', 'Pacote excluído com sucesso')
            this.router.navigate([PathRoutes.Packages.List])
          } else {
            this.helper.triggerNotification(false)
          }
        }).catch(e => console.log(e))
      }
    }).catch(e => console.log(e))
  }

}
