import { PathRoutes } from 'src/app/routes/path-routes';
import { Component, OnInit } from '@angular/core';
import { AdminsService } from 'src/app/services/admins.service';
import { ClientsService } from 'src/app/services/clients.service';

@Component({
  selector: 'app-admins',
  templateUrl: './admins.component.html',
  styleUrls: ['./admins.component.scss']
})
export class AdminsComponent implements OnInit {
  columns = ['Nome', 'E-mail', 'Criação']
  items = []
  loading = false
  PathRoutes = PathRoutes

  page
  q

  constructor(private adminsService: AdminsService,
    private clientsService: ClientsService,
    ) { }

  ngOnInit() {
    this.getItems()
  }

  getItems() {
    this.loading = true
   
    this.clientsService.adminList(this.page,this.q).then((res: any) => {
      console.log({res})
      if (res.status) {
        this.items = res.data

      }
    }).catch(e => console.log(e))
      .finally(() => {
        this.loading = false
      })
  }

}
