import { Injectable, LOCALE_ID } from '@angular/core';
import { NetworkService } from './network.service';
import { Endpoints } from '../routes/endpoints';

@Injectable({
  providedIn: 'root'
})
export class HotelsService {

  constructor(
    private network: NetworkService
  ) { }

  list() {
    let url = `${Endpoints.Hotels}`
    return this.network.get(url)
  }

  show(id) {
    let url = `${Endpoints.Hotels}/${id}`
    return this.network.get(url)
  }

  save(params) {
    let id = params.id
    delete (params.id)
    if (id) {
      let url = `${Endpoints.Hotels}/${id}`
      return this.network.put(url, params)
    } else {
      let url = `${Endpoints.Hotels}`
      return this.network.post(url, params)
    }
  }

  savePhoto(params) {
    let url = `${Endpoints.HotelsPhoto}`
    return this.network.postMultipart(url, params)
  }

  deletePhoto(params) {
    let url = `${Endpoints.DeletePhoto}`
    return this.network.post(url, params)
  }

  delete(id) {
    let url = `${Endpoints.Hotels}/${id}`
    return this.network.delete(url)
  }
}
