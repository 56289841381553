


import { PathRoutes } from 'src/app/routes/path-routes';
import { Component, OnInit } from '@angular/core';
import { MarketingService } from 'src/app/services/marketing.service';
import { HelperService } from 'src/app/helpers/helper.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-leads-report',
  templateUrl: './leads-report.component.html',
  styleUrls: ['./leads-report.component.scss']
})
export class LeadsReportComponent implements OnInit {
  PathRoutes = PathRoutes
  loading = false
  form: FormGroup

  leadInfo = null
  dates = null

  constructor(
    public helper: HelperService,
    private mktService: MarketingService,
    private formBuilder: FormBuilder,
    private router: Router
  ) { }

  ngOnInit() {
    this.setupForm()
  }

  setupForm() {
    this.form = this.formBuilder.group({
      'from': [(new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)).toISOString().substring(0,10), Validators.required],
      'to': [(new Date()).toISOString().substring(0,10), Validators.required]
    })
    this.getInfo()
  }

  getInfo(detail = false) {
    this.loading = true

    this.dates = this.form.getRawValue()

    this.mktService.leadReport(this.dates.from,this.dates.to,detail).then((res: any) => {
      if(res.status){
        this.leadInfo = res.data
      } else {
        // this.helper.triggerNotification(false)
      }
    }).catch(e => console.log(e))
      .finally(() => {
        this.loading = false
      })
  }

}
