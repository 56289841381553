import { PathRoutes } from 'src/app/routes/path-routes';
import { Component, OnInit } from '@angular/core';
import { HotelsService } from 'src/app/services/hotels.service';

@Component({
  selector: 'app-hotels',
  templateUrl: './hotels.component.html',
  styleUrls: ['./hotels.component.scss']
})
export class HotelsComponent implements OnInit {
  PathRoutes = PathRoutes
  columns = ['Nome', 'Descrição', 'Endereço']
  items = []
  loading = false

  constructor(
    private hotelsService: HotelsService
  ) { }

  ngOnInit() {
    this.getItems()
  }

  getItems() {
    this.loading = true

    this.hotelsService.list().then((res: any) => {
      if (res.status) {
        this.items = res.data
      }
    }).catch(e => console.log(e))
      .finally(() => {
        this.loading = false
      })
  }

}
