
import { PathRoutes } from 'src/app/routes/path-routes';
import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';
import { ClientsService } from 'src/app/services/clients.service';
import { HelperService } from 'src/app/helpers/helper.service';


@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit {
  environment = environment
  PathRoutes = PathRoutes
  columns = ['Nome', 'Empresa','Email','Tel','Projetos']
  items = []
  loading = false
  q = ''
  page = 1

  constructor(
    private clientsService: ClientsService, 
    private helper: HelperService,

  ) { }

  ngOnInit() {
    this.getItems()
  }

  clearSearch(){
    alert('aaaa')
    this.q = ''
    this.getItems()
  }

  getItems() {
    this.loading = true

    this.clientsService.list(this.page,this.q).then((res: any) => {
      console.log({res})
      if (res.status) {
        this.items = res.data

        this.items.map(c=>{
          c.phone = this.helper.getFormatttedPhone(c.phone)
        })


      }
    }).catch(e => console.log(e))
      .finally(() => {
        this.loading = false
      })
  }
}
