import { Portuguese } from 'flatpickr/dist/l10n/pt.js';
import { PathRoutes } from 'src/app/routes/path-routes';
import { environment } from 'src/environments/environment';
import { Component, OnInit, ViewChild, ElementRef, Renderer2, Renderer } from '@angular/core';
import { HelperService } from 'src/app/helpers/helper.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectsService } from 'src/app/services/projects.service';
import { ClientsService } from 'src/app/services/clients.service';

import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';

import { FormValidatorsService } from 'src/app/services/form-validators.service';

@Component({
  selector: 'app-projects-add',
  templateUrl: './projects-add.component.html',
  styleUrls: ['./projects-add.component.scss']
})

export class ProjectsAddComponent implements OnInit {

  environment = environment
  PathRoutes = PathRoutes
  id = null
  user_id = null

  loading = false
  form: FormGroup
  item: any
  files: any
  type = 'client'
  loadingForm = false
  Portuguese = Portuguese

  clients = []

  ufs = []

  constructor(
    public helper: HelperService,
    private activatedRoute: ActivatedRoute,
    private projectsService: ProjectsService,
    private clientsService: ClientsService,
    private formValidators: FormValidatorsService,
    private formBuilder: FormBuilder,
    private router: Router
    
  ) {  }

  ngOnInit() {
    this.ufs = this.helper.ufs
    this.id = this.activatedRoute.snapshot.paramMap.get('id')
    this.user_id = this.activatedRoute.snapshot.paramMap.get('user_id')

    this.getClients()


    if (this.id) {
      this.getItem()
    }

    this.loadingForm = true


    
    setTimeout(() => {
      this.setupForm()
      this.loadingForm = false
    }, 1000);
  }

  getClients(){
    this.clientsService.list().then((res: any) => {
      if (res.status) {
        this.clients = res.data

        console.log(this.clients)
      
      }
    }).catch(e => console.log(e))
  }

  getItem() {
    this.projectsService.show(this.id).then((res: any) => {
      console.log({getItem: res})
      if (res.status) {
        console.log('true')
        this.item = res.data
        this.setupForm()
      }
    }).catch(e => console.log(e))
  }


  setupForm() {
    this.form = this.formBuilder.group({
      "id": this.id,
      "user_id": [this.item ? this.item.user_id : this.user_id, Validators.required],

      "title": [this.item ? this.item.title : null, Validators.required],
      "status": [this.item ? this.item.status : 'waiting', Validators.required],

      "value": [this.item ? this.item.value : null, Validators.required],
    })
  }

  save() {
    this.loading = true
    let formValues = this.form.getRawValue()

    var payload = formValues

    // console.log(payload)

    if (!this.form.invalid) {
      this.projectsService.save(payload).then((res: any) => {
        if (res.status) {
          if (this.files) {
            let id = this.id || res.data.id
          } else {
            this.helper.triggerNotification(true)
            this.router.navigate([PathRoutes.Projects.Detail + '/' + res.data.id])
          }
        } else {
          this.helper.triggerNotification(false)
        }
      }).catch(e => console.log(e))
    }
  }


  onSelect(event) {
    this.files = event.addedFiles;
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  cancelUpload() {
    // this.hasImg = true
    this.files.splice(this.files.indexOf(event), 1);
  }

}
