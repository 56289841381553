import { environment } from 'src/environments/environment';
import { PathRoutes } from 'src/app/routes/path-routes';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HelperService } from 'src/app/helpers/helper.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HotelsService } from 'src/app/services/hotels.service';

@Component({
  selector: 'app-hotels-add',
  templateUrl: './hotels-add.component.html',
  styleUrls: ['./hotels-add.component.scss']
})
export class HotelsAddComponent implements OnInit {
  PathRoutes = PathRoutes
  files: File[] = [];
  form: FormGroup
  id = null
  item: any
  loading = false
  environment = environment
  periods = [
    {from:'', to:''}
  ]

  constructor(
    public helper: HelperService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private hotelsService: HotelsService,
    private router: Router
  ) { }

  ngOnInit() {
    this.id = this.activatedRoute.snapshot.paramMap.get('id')
    if (this.id) {
      this.getItem()
    }
    this.setupForm()
  }

  addPeriod(){
    this.periods.push({from:'',to:''})
  }

  getItem() {
    this.hotelsService.show(this.id).then((res: any) => {
      if (res.status) {
        this.item = res.data
        this.form.patchValue(res.data)
      }
    }).catch(e => console.log(e))
  }

  save() {
    this.loading = true
    
    // console.log(this.files)
    // return
    if(!this.form.invalid){
      this.hotelsService.save(this.form.getRawValue()).then((res: any) => {
        if (res.status) {
          if(this.files.length > 0){
            let id = this.id || res.data.id
            this.savePhotos(id)
          } else {
            this.helper.triggerNotification(true)
            this.router.navigate([PathRoutes.Hotels.List])
          }
        }
      }).catch(e => console.log(e))
    }
  }

  savePhotos(id) {
    let count = 0
    this.files.map((elem) => {
      this.hotelsService.savePhoto({ image: elem, hotel_id: id }).then(() => {
        count++
        if (count == this.files.length) {
          this.helper.triggerNotification(true)
          this.router.navigate([PathRoutes.Hotels.List])
        }
      }).catch(e => {
        console.log(e)
        this.helper.triggerNotification(false)
        this.loading = false
      })
    })
  }

  deletePhoto(url) {
    let params = { url: url }
    this.helper.deleteSwal().then((result) => {
      if (result.value) {
        this.hotelsService.deletePhoto(params).then((res: any) => {
          if (res.status) {
            this.getItem()
          }
        }).catch(e => console.log(e))
      }
    })
  }

  setupForm() {
    this.form = this.formBuilder.group({
      "id": this.id,
      "title": ['', Validators.required],
      "description": ['', Validators.required],
      "address": ['', Validators.required]
    })
  }

  onSelect(event) {
    this.files.push(...event.addedFiles);
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

}
