import { Portuguese } from 'flatpickr/dist/l10n/pt.js';
import { PathRoutes } from 'src/app/routes/path-routes';
import { environment } from 'src/environments/environment';
import { Component, OnInit, ViewChild, ElementRef, Renderer2, Renderer } from '@angular/core';
import { HelperService } from 'src/app/helpers/helper.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PackagesService } from 'src/app/services/packages.service';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { CategoriesService } from 'src/app/services/categories.service';
import { HotelsService } from 'src/app/services/hotels.service';
import { FormValidatorsService } from 'src/app/services/form-validators.service';


@Component({
  selector: 'app-packages-add',
  templateUrl: './packages-add.component.html',
  styleUrls: ['./packages-add.component.scss']
})

export class PackagesAddComponent implements OnInit {
  @ViewChild("from", {static: false}) from: ElementRef
  @ViewChild("price", {static: false}) price: ElementRef

  environment = environment
  PathRoutes = PathRoutes
  id = null
  loading = false
  form: FormGroup
  item: any
  categories = []
  files: any
  hotels = []
  loadingForm = false
  hasImg = false
  Portuguese = Portuguese
  dateFrom
  dateTo
  types = [
    {id: 0, name: 'Tipo 1'},
    {id: 1, name: 'Tipo 2'},
    {id: 2, name: 'Tipo 3'}
  ]

  constructor(
    public helper: HelperService,
    private activatedRoute: ActivatedRoute,
    private packagesService: PackagesService,
    private formBuilder: FormBuilder,
    private router: Router,
    private categoriesService: CategoriesService,
    private hotelsService: HotelsService,
    private formValidators: FormValidatorsService
  ) {  }

  ngOnInit() {
    this.id = this.activatedRoute.snapshot.paramMap.get('id')
    if (this.id) {
      this.getItem()
    }
    this.getHotels()
    this.getCategories()
    this.loadingForm = true

    
    setTimeout(() => {
      this.setupForm()
      console.log(this.form.value)
      this.loadingForm = false
    }, 1000);
  }

  getItem() {
    this.packagesService.show(this.id).then((res: any) => {
      if (res.status) {
        this.item = res.data
        if (this.item.photo) {
          this.hasImg = true
        }
      }
    }).catch(e => console.log(e))
  }

  getHotels() {
    this.hotelsService.list().then((res: any) => {
      if (res.status) {
        this.hotels = res.data
      }
    }).catch(e => console.log(e))
  }

  getCategories() {
    this.categoriesService.list().then((res: any) => {
      if (res.status) {
        this.categories = res.data
      }
    }).catch(e => console.log(e))
  }

  get optionsArray() {
    return <FormArray>this.form.get('options')
  }

  buildOptions() {
    return this.hotels.map(v => {
      return this.formBuilder.group({
        'hotel_id': this.item ? this.item.options.some(item => item.hotel_id == v.id) : false,
        'type': this.item ? this.item.options.filter(item => item.hotel_id == v.id).map(item => item.type) : null,
        'price': this.item ? this.item.options.filter(item => item.hotel_id == v.id).map(item => item.price) : null,
      })
    })
  }

  buildCategories() {
    const categories = this.categories
      .map(v => new FormControl(this.item ? (this.item.categories.some(item => item.pivot.category_id == v.id)) : false))
    return this.formBuilder.array(categories, this.formValidators.categories(1))
  }

  buildPeriods() {
    return this.formBuilder.group({
      'from': [null, Validators.required],
      'to': [null, Validators.required]
    })
  }

  buildItemPeriods() {
    return this.item.periods.map(v => {
      return this.formBuilder.group({
        'from': [v.from, Validators.required],
        'to': [v.to, Validators.required]
      })
    })
  }

  validatorOptions(index){
    if(this.optionsArray.controls[index]['controls']['hotel_id'].value){
      this.optionsArray.controls[index]['controls']['price'].setValidators([Validators.required]);
      this.optionsArray.controls[index]['controls']['type'].setValidators([Validators.required]);
    } else {
      this.optionsArray.controls[index]['controls']['price'].clearValidators();
      this.optionsArray.controls[index]['controls']['type'].clearValidators();
    }
    this.optionsArray.controls[index]['controls']['price'].updateValueAndValidity()
    this.optionsArray.controls[index]['controls']['type'].updateValueAndValidity()
  }

  setupForm() {
    this.form = this.formBuilder.group({
      "id": this.id,
      "title": [this.item ? this.item.title : null, Validators.required],
      "discount": [this.item ? this.item.discount : null, Validators.required],
      "subtitle": [this.item ? this.item.subtitle : null, Validators.required],
      "location": [this.item ? this.item.location : null, Validators.required],

      "description": [this.item ? this.item.description : null, Validators.required],
      "categories": this.buildCategories(),
      "options": this.formBuilder.array(this.buildOptions(), this.formValidators.options()),
      "periods": (this.item && this.item.periods.length > 0) ? this.formBuilder.array(this.buildItemPeriods()) : this.formBuilder.array([this.buildPeriods()])
    })
  }

  validatorPeriods(){
    if(this.from.nativeElement.value == ''){
      this.from.nativeElement.nextSibling.classList.add('is-invalid')
    } else {
      this.from.nativeElement.nextSibling.classList.remove('is-invalid')
    }
  }

  save() {
    this.loading = true
    let formValues = this.form.getRawValue()

    formValues = {...formValues, 
      options: formValues.options
        .map((v, i) => v.hotel_id ? { ...v, hotel_id: this.hotels[i].id } : null)
        .filter(v => v != null),
      categories: formValues.categories
        .map((v, i) => v ? this.categories[i].id : null)
        .filter(v => v != null)
    }

    if (!this.form.invalid) {
      this.packagesService.save(formValues).then((res: any) => {
        if (res.status) {
          if (this.files) {
            let id = this.id || res.data.id
            this.savePhoto(id)
          } else {
            this.helper.triggerNotification(true)
            this.router.navigate([PathRoutes.Packages.List])
          }
        } else {
          this.helper.triggerNotification(false)
        }
      }).catch(e => console.log(e))
    }
  }

  savePhoto(id) {
    this.packagesService.savePhoto({ image: this.files[0], package_id: id }).then((res: any) => {
      if (res.status) {
        this.helper.triggerNotification(true)
        this.router.navigate([PathRoutes.Packages.List])
      } else {
        this.helper.triggerNotification(false)
      }
    }).catch(e => console.log(e))
  }

  get periodsArray() {
    return <FormArray>this.form.get('periods')
  }

  addPeriod() {
    this.periodsArray.push(this.buildPeriods())
  }

  deletePeriod(index) {
    this.periodsArray.removeAt(index)
  }

  onSelect(event) {
    this.files = event.addedFiles;
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  cancelUpload() {
    this.hasImg = true
    this.files.splice(this.files.indexOf(event), 1);
  }

}
