import { Component, OnInit } from '@angular/core';
import { PathRoutes } from 'src/app/routes/path-routes';
import { environment } from 'src/environments/environment';
import { HelperService } from 'src/app/helpers/helper.service';
import { Router, ActivatedRoute } from '@angular/router';

import { ProjectsService } from 'src/app/services/projects.service';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})

export class ProjectsComponent implements OnInit {
  environment = environment
  PathRoutes = PathRoutes
  items: any

  totalInPeriod = 0;

  constructor(
    public helper: HelperService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private projectsService: ProjectsService
  ) { }

  ngOnInit() {
    this.getItem()
  }

  getItem() {
    this.projectsService.list().then((res: any) => {
      console.log({res})
      if(res.status){
        this.items = res.data

        this.items.map(e => {
          this.totalInPeriod += e.value
        })
      }
    }).catch(e => console.log(e))
  }

}
