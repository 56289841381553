import { PathRoutes } from 'src/app/routes/path-routes';
import { Component, OnInit } from '@angular/core';
import { AdminsService } from 'src/app/services/admins.service';
import { HelperService } from 'src/app/helpers/helper.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admins-add',
  templateUrl: './admins-add.component.html',
  styleUrls: ['./admins-add.component.scss']
})
export class AdminsAddComponent implements OnInit {
  PathRoutes = PathRoutes
  loading = false
  form: FormGroup

  constructor(
    public helper: HelperService,
    private adminsService: AdminsService,
    private formBuilder: FormBuilder,
    private router: Router
  ) { }

  ngOnInit() {
    this.setupForm()
  }

  setupForm() {
    this.form = this.formBuilder.group({
      'type': ['admin', Validators.required],
      'name': [null, Validators.required],
      'email': [null, Validators.compose([Validators.required, Validators.email])],
      'password': [null, Validators.compose([Validators.required, Validators.minLength(6)])],
      'confirm_password': null
    })
  }

  save() {
    this.loading = true
    this.adminsService.save(this.form.getRawValue()).then((res: any) => {
      if(res.status){
        this.helper.triggerNotification(true)
        this.router.navigate([PathRoutes.Admins.List])
      } else {
        this.helper.triggerNotification(false)
      }
    }).catch(e => console.log(e))
      .finally(() => {
        this.loading = false
      })
  }

}
