import { Endpoints } from './../routes/endpoints';
import { Injectable } from '@angular/core';
import { NetworkService } from './network.service';

@Injectable({
  providedIn: 'root'
})
export class AdminsService {

  constructor(private network: NetworkService) { }

  homeStats(){
    let url = `${Endpoints.AdminHomeStats}`
    return this.network.get(url)
  }
  
  save(params){
    let url = `${Endpoints.Users}`
    return this.network.post(url, params)
  }

  
}
