import { Injectable } from '@angular/core';
import { NetworkService } from './network.service';
import { Endpoints } from '../routes/endpoints';

@Injectable({
  providedIn: 'root'
})
export class ProjectsService {

  constructor(
    private network: NetworkService
  ) { }

  list(page =1, q='') {
    let url = `${Endpoints.Projects}` + '?page='+page+'&q='+q
    return this.network.get(url)
  }

  show(id) {
    let url = `${Endpoints.Projects}/${id}`
    return this.network.get(url)
  }

  save(params) {
    let id = params.id
    delete (params.id)
    if (id) {
      let url = `${Endpoints.Projects}/${id}`
      return this.network.put(url, params)
    } else {
      let url = `${Endpoints.Projects}`
      return this.network.post(url, params)
    }
  }
  newPayment(params) {
      let url = `${Endpoints.ProjectPayments}`
      return this.network.post(url, params)
  }
  newRecurring(params) {
    let url = `${Endpoints.ProjectRecurring}`
    return this.network.post(url, params)
  }
  newFile(params) {
    let url = `${Endpoints.ProjectFiles}`
    return this.network.postMultipart(url, params)
  }
  newComment(params) {
    let url = `${Endpoints.ProjectComments}`
    return this.network.post(url, params)
  }
  newProduct(params) {
    let url = `${Endpoints.ProjectProducts}`
    return this.network.post(url, params)
  }

  delete(id,type){
    var url
    switch(type){
      case 'payment':
        url = `${Endpoints.ProjectPayments}`
        break;
      case 'recurring':
        url = `${Endpoints.ProjectRecurring}`
        break;
      case 'file':
        url = `${Endpoints.ProjectFiles}`
        break;
        case 'product':
        url = `${Endpoints.ProjectProducts}`
        break;
        case 'comment':
        url = `${Endpoints.ProjectComments}`
        break;
    }

    url += '/' + id

    return this.network.delete(url)

  }

  
  // delete(id) {
  //   let url = `${Endpoints.Projects}/${id}`
  //   return this.network.delete(url)
  // }

}
