import { PathRoutes } from 'src/app/routes/path-routes';
import { Component, OnInit } from '@angular/core';
import { CategoriesService } from 'src/app/services/categories.service';
import { HotelsService } from 'src/app/services/hotels.service';
import { PackagesService } from 'src/app/services/packages.service';
import { AdminsService } from 'src/app/services/admins.service';
import { OrdersService } from 'src/app/services/orders.service';
import { HelperService } from 'src/app/helpers/helper.service';
 
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  PathRoutes = PathRoutes
  stats

  constructor(
    private categoriesService: CategoriesService,
    private hotelsService: HotelsService,
    private packagesService: PackagesService,
    private adminsService: AdminsService,
    private ordersService: OrdersService,
    public helper: HelperService,

  ) { }

  ngOnInit(): void {
    this.getItems()
  }

  getItems(){
  
    this.adminsService.homeStats().then((res:any) => {
      if(res.status){
       this.stats = res.data
      }
    }).catch(e => console.log())

  }

}
