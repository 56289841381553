import { HelperService } from 'src/app/helpers/helper.service';
import { Component, OnInit } from '@angular/core';
import { CategoriesService } from 'src/app/services/categories.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { analyzeAndValidateNgModules } from '@angular/compiler';
declare var $: any

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {
  columns = ['Nome', 'Criada em', 'Ações']
  items = []
  form: FormGroup
  loading = false
  hasItem = false

  constructor(
    private formBuilder: FormBuilder,
    private categoriesService: CategoriesService,
    public helper: HelperService
  ) { }

  ngOnInit() {
    this.getItems()
    this.setupForm()
  }

  setupForm() {
    this.hasItem = false
    this.form = this.formBuilder.group({
      'id': null,
      'title': [null, Validators.required]
    })
  }

  getItems() {
    this.loading = true
    this.categoriesService.list().then((res: any) => {
      if (res.status) {
        this.items = res.data
      }
    }).catch(e => console.log(e))
      .finally(() => {
        this.loading = false
      })
  }

  save() {
    this.loading = true
    let params = this.form.getRawValue()
    this.categoriesService.save(params).then((res: any) => {
      if (res.status) {
        this.helper.triggerNotification(true)
        this.getItems()
      } else {
        this.helper.triggerNotification(false)
      }
      $('#modalCategories').modal('hide')
    }).catch(e => console.log(e))
      .finally(() => this.loading = false)
  }

  getItem(id) {
    this.categoriesService.show(id).then((res: any) => {
      if (res.status) {
        this.hasItem = true
        this.form.get('id').setValue(res.data.id)
        this.form.patchValue(res.data)
      }
    }).catch(e => console.log(e))
  }

  delete(id) {
    this.helper.deleteSwal().then((result) => {
      if (result.value) {
        this.categoriesService.delete(id).then((res: any) => {
          if (res.status) {
            this.helper.triggerNotification(true)
            this.getItems()
          } else {
            this.helper.triggerNotification(false)
          }
        }).catch(e => console.log(e))
      }
    }).catch(e => console.log(e))
  }

}
