import { Injectable } from '@angular/core';
import { NetworkService } from './network.service';
import { Endpoints } from '../routes/endpoints';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  constructor(
    private network: NetworkService
  ) { }

  list() {
    let url = `${Endpoints.Orders}`
    return this.network.get(url)
  }
  
  show(id) {
    let url = `${Endpoints.Orders}/${id}`
    return this.network.get(url)
  }

}
