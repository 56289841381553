import { HelperService } from 'src/app/helpers/helper.service';
import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';
import { HotelsService } from 'src/app/services/hotels.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PathRoutes } from 'src/app/routes/path-routes';

@Component({
  selector: 'app-hotels-detail',
  templateUrl: './hotels-detail.component.html',
  styleUrls: ['./hotels-detail.component.scss']
})
export class HotelsDetailComponent implements OnInit {
  item: any
  id = null
  environment = environment
  PathRoutes = PathRoutes
  loading = true

  constructor(
    public helper: HelperService,
    private hotelsService: HotelsService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.getItem()
  }

  getItem() {
    this.loading = true
    this.hotelsService.show(this.id).then((res: any) => {
      if (res.status) {
        this.item = res.data
        console.log(this.item)
      }
    }).catch(e => console.log(e))
      .finally(() => {
        this.loading = false
      })
  }

  delete() {
    this.helper.deleteSwal().then((result) => {
      if (result.value) {
        this.hotelsService.delete(this.id).then((res:any) => {
          if(res.status){
            this.helper.triggerNotification(true, 'Excluído', 'Hotél excluído com sucesso')
            this.router.navigate([PathRoutes.Hotels.List])
          } else {
            this.helper.triggerNotification(false)
          }
        }).catch(e => console.log(e))
      }
    }).catch(e => console.log(e))
  }

}
