import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PathRoutes } from 'src/app/routes/path-routes';
import { AccountService } from 'src/app/services/account.service';
import { SessionService } from 'src/app/auth/session.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  form: FormGroup
  loading = false
  message = ''
  PathRoutes = PathRoutes

  constructor(
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    private sessionService: SessionService,
    private router: Router
  ) {
    this.form = this.formBuilder.group({
      'email': [null, Validators.compose([Validators.required, Validators.email])],
      'password': [null, Validators.compose([Validators.required, Validators.minLength(6)])]
    })
  }

  login() {

    this.loading = true
    this.message = ''

    this.accountService.login(this.form.getRawValue()).then((res: any) => {
      if (res.status && res.data.type == 'admin') {
        res.data.access_token = res.access_token
        this.sessionService.saveSession(res.data)
        this.router.navigate([PathRoutes.Home])
      } else {
        this.message = 'Usuário ou senha inválidos'
      }
    })
      .catch(e => console.log(e))
      .finally(() => { this.loading = false })
  }

  ngOnInit() {
  }

}
