export const PathRoutes = Object({
    Home: '/home',
    Login: '/login',
    PasswordReset: '/alterar-senha',
    PasswordEmail: '/esqueci-minha-senha',
    Categories: {
        List: '/categorias'
    },
    Users: {
        List: '/usuarios',
        Detail: 'usuarios/detalhes'
    },
    Clients: {
        List: '/clientes',
        Add: '/clientes/adicionar',
        Edit: '/clientes/editar',
        Detail: '/clientes/detalhes'
    },
    Projects: {
        List: '/projetos',
        Add: '/projetos/adicionar',
        Edit: '/projetos/editar',
        Detail: '/projetos/detalhes'
    },
    Financial: {
        List: '/financial'
    },
    Hotels: {
        List: '/hoteis',
        Add: '/hoteis/adicionar',
        Edit: '/hoteis/editar',
        Detail: '/hoteis/detalhes'
    },
   
    Orders: {
        List: '/pedidos',
        Detail: '/pedidos/detalhes'
    },
    Packages: {
        List: '/pacotes',
        Add: '/pacotes/adicionar',
        Edit: '/pacotes/editar',
        Detail: '/pacotes/detalhes'
    },
    Admins: {
        List: '/administradores',
        Add: '/administradores/adicionar'
    },
    Marketing: {
        LeadReport: '/marketing/lead-report',
    }
});



