import { PathRoutes } from 'src/app/routes/path-routes';
import { Observable } from 'rxjs';
import { SessionService } from 'src/app/auth/session.service';
import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public isLoggedIn: boolean = false;
	public redirectUrl: string;
	constructor(private router: Router, private sessionService: SessionService) { }

	public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		this.redirectUrl = state.url
		var hasSession = this.sessionService.getSession()
		var hasRoleAccess = false
		if(hasSession){
			let roles = route.data.roles as Array<string>;

			hasRoleAccess = roles.includes(hasSession.role)
		}
		

		if (!hasSession || !hasRoleAccess) {
			this.router.navigate([PathRoutes.Login])
		}
		return true
	}
}
