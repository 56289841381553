import { PathRoutes } from 'src/app/routes/path-routes';
import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/helpers/helper.service';
import { Router, ActivatedRoute } from '@angular/router';

import { ClientsService } from 'src/app/services/clients.service';


@Component({
  selector: 'app-clients-detail',
  templateUrl: './clients-detail.component.html',
  styleUrls: ['./clients-detail.component.scss']
})
export class ClientsDetailComponent implements OnInit {
  environment = environment
  PathRoutes = PathRoutes
  item: any
  id = null
  
  constructor(
    public helper: HelperService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private clientsService: ClientsService
  ) { }

  ngOnInit() {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.getItem(this.id)
  }

  getItem(id) {
    this.clientsService.show(id).then((res: any) => {
      console.log({res})
      if(res.status){
        this.item = res.data
      }
    }).catch(e => console.log(e))
  }

  delete(){
    this.helper.deleteSwal().then((result) => {
      if(result.value){
        this.clientsService.delete(this.id).then((res:any) => {
          if(res.status){
            this.helper.triggerNotification(true, 'Excluído', 'Pacote excluído com sucesso')
            this.router.navigate([PathRoutes.Clients.List])
          } else {
            this.helper.triggerNotification(false)
          }
        }).catch(e => console.log(e))
      }
    }).catch(e => console.log(e))
  }


  sendPassword() {
    this.helper.sendPWSwal().then((result) => {
      if(result.value){
        this.clientsService.sendPassword(this.id).then((res:any) => {
          if(res.status){
            this.helper.triggerNotification(true, 'Enviado', 'Email enviado')
            this.router.navigate([PathRoutes.Clients.List])
          } else {
            this.helper.triggerNotification(false)
          }
          }).catch(e => console.log(e))
        }
      }).catch(e => console.log(e))
    }

}
