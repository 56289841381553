import { PathRoutes } from 'src/app/routes/path-routes';
import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';
import { PackagesService } from 'src/app/services/packages.service';

@Component({
  selector: 'app-packages',
  templateUrl: './packages.component.html',
  styleUrls: ['./packages.component.scss']
})
export class PackagesComponent implements OnInit {
  environment = environment
  PathRoutes = PathRoutes
  columns = ['', 'Nome', 'Descrição','Localização','Resumo','Desconto']
  items = []
  loading = false

  constructor(
    private packagesService: PackagesService
  ) { }

  ngOnInit() {
    this.getItems()
  }

  getItems() {
    this.loading = true

    this.packagesService.list().then((res: any) => {
      if (res.status) {
        this.items = res.data
      }
    }).catch(e => console.log(e))
      .finally(() => {
        this.loading = false
      })
  }
}
