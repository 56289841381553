
export const Endpoints = Object.freeze({
        Login: 'login',
        Register: 'register',
        Categories: 'categories',
        Hotels: 'hotels',
        HotelsPhoto: 'hotels/photo',
        DeletePhoto: 'hotels/deletePhoto',
        Orders: 'orders',
        Packages: 'packages',
        PackagesPhoto: 'packages/photo',
        Admins: 'admins',

        ///ph shit

        Users: 'users',
        ResendPassword: 'resend-password',
        LeadReport: 'marketing/lead-report',


        Projects: 'projects',
        ProjectPayments: 'projects/payments',
        ProjectRecurring: 'projects/recurring-payments',
        ProjectComments: 'projects/comments',
        ProjectFiles: 'projects/files',

        Financial: 'financial',
        Transactions: 'transactions',

        
        ProjectProducts: 'projects/products',

        AdminHomeStats: 'home/stats',



});
