import { AdminsComponent } from './../pages/admins/admins.component';
import { PathRoutes } from './path-routes';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from '../pages/home/home.component';
import { AuthService } from '../auth/auth.service';
import { CategoriesComponent } from '../pages/categories/categories.component';
import { HotelsComponent } from '../pages/hotels/hotels.component';
import { HotelsDetailComponent } from '../pages/hotels/hotels-detail/hotels-detail.component';
import { PackagesComponent } from '../pages/packages/packages.component';
import { PackagesDetailComponent } from '../pages/packages/packages-detail/packages-detail.component';
import { OrdersComponent } from '../pages/orders/orders.component';
import { OrdersDetailComponent } from '../pages/orders/orders-detail/orders-detail.component';
import { LoginComponent } from '../pages/login/login.component';
import { HotelsAddComponent } from '../pages/hotels/hotels-add/hotels-add.component';
import { PackagesAddComponent } from '../pages/packages/packages-add/packages-add.component';
import { AdminsAddComponent } from '../pages/admins/admins-add/admins-add.component';
import { ClientsComponent } from '../pages/clients/clients.component';
import { ClientsDetailComponent } from '../pages/clients/clients-detail/clients-detail.component';
import { ClientsAddComponent } from '../pages/clients/clients-add/clients-add.component';

import { ProjectsComponent } from '../pages/projects/projects.component';
import { ProjectsDetailComponent } from '../pages/projects/projects-detail/projects-detail.component';
import { ProjectsAddComponent } from '../pages/projects/projects-add/projects-add.component';

import { FinancialComponent } from '../pages/financial/financial.component';
import { LeadsReportComponent } from '../pages/marketing/leads-report/leads-report.component';


const routes: Routes = [
  { path: PathRoutes.Login.replace('/', ''), component: LoginComponent},

  { path: '', component: HomeComponent, canActivate: [AuthService], data: {roles: ['full','marketing']}},
  { path: PathRoutes.Home.replace('/', ''), component: HomeComponent, canActivate: [AuthService], data: {roles: ['full','marketing']}},

  { path: PathRoutes.Categories.List.replace('/', ''), component: CategoriesComponent, canActivate: [AuthService], data: {roles: ['full']}},

//// ph

{ path: PathRoutes.Clients.List.replace('/', ''), component: ClientsComponent, canActivate: [AuthService], data: {roles: ['full']}},
{ path: PathRoutes.Clients.Add.replace('/', ''), component: ClientsAddComponent, canActivate: [AuthService], data: {roles: ['full']}},
{ path: PathRoutes.Clients.Edit.replace('/', '')+'/:id', component: ClientsAddComponent, canActivate: [AuthService], data: {roles: ['full']}},
{ path: PathRoutes.Clients.Detail.replace('/', '')+'/:id', component: ClientsDetailComponent, canActivate: [AuthService], data: {roles: ['full']}},

{ path: PathRoutes.Projects.List.replace('/', ''), component: ProjectsComponent, canActivate: [AuthService], data: {roles: ['full']}},
{ path: PathRoutes.Projects.Add.replace('/', ''), component: ProjectsAddComponent, canActivate: [AuthService], data: {roles: ['full']}},
{ path: PathRoutes.Projects.Edit.replace('/', '')+'/:id', component: ProjectsAddComponent, canActivate: [AuthService], data: {roles: ['full']}},
{ path: PathRoutes.Projects.Detail.replace('/', '')+'/:id', component: ProjectsDetailComponent, canActivate: [AuthService], data: {roles: ['full']}},

{ path: PathRoutes.Financial.List.replace('/', ''), component: FinancialComponent, canActivate: [AuthService], data: {roles: ['full']}},
{ path: PathRoutes.Marketing.LeadReport.replace('/', ''), component: LeadsReportComponent, canActivate: [AuthService], data: {roles: ['full','marketing']}},



// end of ph

  { path: PathRoutes.Hotels.List.replace('/', ''), component: HotelsComponent, canActivate: [AuthService], data: {roles: ['full']}},
  { path: PathRoutes.Hotels.Add.replace('/', ''), component: HotelsAddComponent, canActivate: [AuthService], data: {roles: ['full']}},
  { path: PathRoutes.Hotels.Edit.replace('/', '')+'/:id', component: HotelsAddComponent, canActivate: [AuthService], data: {roles: ['full']}},
  { path: PathRoutes.Hotels.Detail.replace('/', '')+'/:id', component: HotelsDetailComponent, canActivate: [AuthService], data: {roles: ['full']}},
  
  { path: PathRoutes.Packages.List.replace('/', ''), component: PackagesComponent, canActivate: [AuthService], data: {roles: ['full']}},
  { path: PathRoutes.Packages.Add.replace('/', ''), component: PackagesAddComponent, canActivate: [AuthService], data: {roles: ['full']}},
  { path: PathRoutes.Packages.Edit.replace('/', '')+'/:id', component: PackagesAddComponent, canActivate: [AuthService], data: {roles: ['full']}},
  { path: PathRoutes.Packages.Detail.replace('/', '')+'/:id', component: PackagesDetailComponent, canActivate: [AuthService], data: {roles: ['full']}},
  
  { path: PathRoutes.Orders.List.replace('/', ''), component: OrdersComponent, canActivate: [AuthService], data: {roles: ['full']}},
  { path: PathRoutes.Orders.Detail.replace('/', '')+'/:id', component: OrdersDetailComponent, canActivate: [AuthService], data: {roles: ['full']}},

  { path: PathRoutes.Admins.List.replace('/', ''), component: AdminsComponent, canActivate: [AuthService], data: {roles: ['full']}},
  { path: PathRoutes.Admins.Add.replace('/', ''), component: AdminsAddComponent, canActivate: [AuthService], data: {roles: ['full']}},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
