import { OrdersService } from 'src/app/services/orders.service';
import { PathRoutes } from 'src/app/routes/path-routes';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {
  PathRoutes = PathRoutes
  columns = ['Pedido', 'Pacote', 'Hotel', 'Pessoas', 'Período', 'Status']
  items = []
  loading = false

  constructor(
    private ordersService: OrdersService
  ) { }

  ngOnInit() {
    this.getItems()
  }

  getItems() {
    this.loading = true

    this.ordersService.list().then((res: any) => {
      if (res.status) {
        this.items = res.data
      }
    }).catch(e => console.log(e))
      .finally(() => {
        this.loading = false
      })
  }

}
