import { Component, OnInit } from '@angular/core';
import { PathRoutes } from 'src/app/routes/path-routes';
import { SessionService } from 'src/app/auth/session.service'; 

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent implements OnInit {
  user = null

  PathRoutes = PathRoutes

  
  constructor(private session: SessionService) {

		this.user = this.session.getSession()

   }

  ngOnInit() {
    
  }

}
