import { Portuguese } from 'flatpickr/dist/l10n/pt.js';
import { PathRoutes } from 'src/app/routes/path-routes';
import { environment } from 'src/environments/environment';
import { Component, OnInit, ViewChild, ElementRef, Renderer2, Renderer } from '@angular/core';
import { HelperService } from 'src/app/helpers/helper.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientsService } from 'src/app/services/clients.service';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { CategoriesService } from 'src/app/services/categories.service';
import { HotelsService } from 'src/app/services/hotels.service';
import { FormValidatorsService } from 'src/app/services/form-validators.service';


@Component({
  selector: 'app-clients-add',
  templateUrl: './clients-add.component.html',
  styleUrls: ['./clients-add.component.scss']
})

export class ClientsAddComponent implements OnInit {
  @ViewChild("from", {static: false}) from: ElementRef
  @ViewChild("price", {static: false}) price: ElementRef

  environment = environment
  PathRoutes = PathRoutes
  id = null
  loading = false
  form: FormGroup
  item: any
  // categories = []
  files: any
  type = 'client'
  // hotels = []
  loadingForm = false
  // hasImg = false
  Portuguese = Portuguese
  dateFrom
  dateTo
  ufs = []
  // types = [
  //   {id: 0, name: 'Tipo 1'},
  //   {id: 1, name: 'Tipo 2'},
  //   {id: 2, name: 'Tipo 3'}
  // ]

  constructor(
    public helper: HelperService,
    private activatedRoute: ActivatedRoute,
    private clientsService: ClientsService,
    private formBuilder: FormBuilder,
    private router: Router,
    private categoriesService: CategoriesService,
    private hotelsService: HotelsService,
    private formValidators: FormValidatorsService
  ) {  }

  ngOnInit() {
    this.ufs = this.helper.ufs
    this.id = this.activatedRoute.snapshot.paramMap.get('id')
    if (this.id) {
      this.getItem()
    }
    // this.getHotels()
    // this.getCategories()
    this.loadingForm = true

    
    setTimeout(() => {
      this.setupForm()
      this.loadingForm = false
    }, 1000);
  }

  getItem() {
    this.clientsService.show(this.id).then((res: any) => {
      console.log({res})
      if (res.status) {
        this.item = res.data
      
      }
    }).catch(e => console.log(e))
  }

  // getHotels() {
  //   this.hotelsService.list().then((res: any) => {
  //     if (res.status) {
  //       this.hotels = res.data
  //     }
  //   }).catch(e => console.log(e))
  // }

  // getCategories() {
  //   this.categoriesService.list().then((res: any) => {
  //     if (res.status) {
  //       this.categories = res.data
  //     }
  //   }).catch(e => console.log(e))
  // }

  // get optionsArray() {
  //   return <FormArray>this.form.get('options')
  // }

  // buildOptions() {
  //   return this.hotels.map(v => {
  //     return this.formBuilder.group({
  //       'hotel_id': this.item ? this.item.options.some(item => item.hotel_id == v.id) : false,
  //       'type': this.item ? this.item.options.filter(item => item.hotel_id == v.id).map(item => item.type) : null,
  //       'price': this.item ? this.item.options.filter(item => item.hotel_id == v.id).map(item => item.price) : null,
  //     })
  //   })
  // }

  // buildCategories() {
  //   const categories = this.categories
  //     .map(v => new FormControl(this.item ? (this.item.categories.some(item => item.pivot.category_id == v.id)) : false))
  //   return this.formBuilder.array(categories, this.formValidators.categories(1))
  // }

  // buildPeriods() {
  //   return this.formBuilder.group({
  //     'from': [null, Validators.required],
  //     'to': [null, Validators.required]
  //   })
  // }

  // buildItemPeriods() {
  //   return this.item.periods.map(v => {
  //     return this.formBuilder.group({
  //       'from': [v.from, Validators.required],
  //       'to': [v.to, Validators.required]
  //     })
  //   })
  // }

  // validatorOptions(index){
  //   if(this.optionsArray.controls[index]['controls']['hotel_id'].value){
  //     this.optionsArray.controls[index]['controls']['price'].setValidators([Validators.required]);
  //     this.optionsArray.controls[index]['controls']['type'].setValidators([Validators.required]);
  //   } else {
  //     this.optionsArray.controls[index]['controls']['price'].clearValidators();
  //     this.optionsArray.controls[index]['controls']['type'].clearValidators();
  //   }
  //   this.optionsArray.controls[index]['controls']['price'].updateValueAndValidity()
  //   this.optionsArray.controls[index]['controls']['type'].updateValueAndValidity()
  // }

  setupForm() {



    this.form = this.formBuilder.group({
      "id": this.id,
      "name": [this.item ? this.item.name : null, Validators.required],
      "company": [this.item ? this.item.company : null, Validators.required],
      "email": [this.item ? this.item.email : null, Validators.required],
      "phone": [this.item ? this.item.phone : null, Validators.required],
      "document": [this.item ? this.item.document : null, []],
      "type": [this.item ? this.item.type : null, []],
      "city": [this.item ? this.item.address.city : null, []],
      "state": [this.item ? this.item.address.state : 'SP', []],
      "neighborhood": [this.item ? this.item.address.neighborhood : null, []],
      "street": [this.item ? this.item.address.street : null, []],
      "number": [this.item ? this.item.address.number : null, []],
      "complement": [this.item ? this.item.address.complement : null, []],
      "zip_code": [this.item ? this.item.address.zip_code : null, []],
    })
  }

  validatorPeriods(){
    if(this.from.nativeElement.value == ''){
      this.from.nativeElement.nextSibling.classList.add('is-invalid')
    } else {
      this.from.nativeElement.nextSibling.classList.remove('is-invalid')
    }
  }

  save() {
    this.loading = true
    let formValues = this.form.getRawValue()

    var payload = {...formValues, address: formValues, type: this.type}

    // console.log(payload)

    if (!this.form.invalid) {
      this.clientsService.save(payload).then((res: any) => {
        if (res.status) {
          if (this.files) {
            let id = this.id || res.data.id
          } else {
            this.helper.triggerNotification(true)
            this.router.navigate([PathRoutes.Clients.List])
          }
        } else {
          this.helper.triggerNotification(false)
        }
      }).catch(e => console.log(e))
    }
  }

  cepComplete() {
    if (this.form.get('zip_code').value.length == 8) {
      return this.clientsService.getCep(this.form.get('zip_code').value)
        .then(res => {

          // var uf = this.ufs.filter(r => r.sigla == res.uf)[0]?.sigla

          console.log({res})

          this.form.patchValue({
            street: res.logradouro,
            neighborhood: res.bairro,
            city: res.localidade,
            state: res.uf,
            // complement: res.complemento
          });
        })
        .catch(error => {
          console.log(error)
          this.form.get('zip_code').setValue(null)
        });
    }
  }


  onSelect(event) {
    this.files = event.addedFiles;
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  cancelUpload() {
    // this.hasImg = true
    this.files.splice(this.files.indexOf(event), 1);
  }

}
