import { Injectable } from '@angular/core';
import { NetworkService } from './network.service';
import { Endpoints } from '../routes/endpoints';

@Injectable({
  providedIn: 'root'
})
export class MarketingService {

  constructor(
    private network: NetworkService
  ) { }

  leadReport(from,to,detail) {
    let url = `${Endpoints.LeadReport}` + '?from='+from+'&to='+to+ '&type=' + (detail ? 'detail' : '')
    return this.network.get(url)
  }


}
