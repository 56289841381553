import { Component, OnInit } from '@angular/core';
import { PathRoutes } from 'src/app/routes/path-routes';
import { environment } from 'src/environments/environment';
import { TransactionsService } from 'src/app/services/transactions.service';
import { HelperService } from 'src/app/helpers/helper.service';

import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { FormValidatorsService } from 'src/app/services/form-validators.service';
import { ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-financial',
  templateUrl: './financial.component.html',
  styleUrls: ['./financial.component.scss']
})
export class FinancialComponent implements OnInit {


   //form shit
   @ViewChild('closeBtn', {static: false}) closeBtn: ElementRef;
   form: FormGroup

  environment = environment
  PathRoutes = PathRoutes
  columns = ['Vencimento', 'Cliente','Projeto','Valor','Status','Boleto','Danfe']
  items = []
  loading = false
  q = ''
  page = 1

  files: any

  selectedId = null


  totals = {
    total: 0,
    pending: 0,
    paid: 0
  }

  constructor(
    private helper: HelperService,
    private tran: TransactionsService,
    private formValidators: FormValidatorsService,
    private formBuilder: FormBuilder,

  ) { }

  ngOnInit() {
    this.getItems()
    this.setupForm()
  }

  getItems() {
    this.loading = true

    this.tran.list(this.page,this.q).then((res: any) => {
      console.log(res)
      // if (res.status) {
        this.items = res.data

        this.items.map((i)=>{
          this.totals.total += i.amount
          if(i.status == 'paid') this.totals.paid += i.amount
          if(i.status == 'waiting_payment') this.totals.pending += i.amount

        })

      // }
    }).catch(e => console.log(e))
      .finally(() => {
        this.loading = false
      })
  }


  private closeModal(): void {
    this.closeBtn.nativeElement.click();
    } 


    saveModal() {
      this.loading = true
      let formValues = this.form.getRawValue()
  
      var apiCall;

      this.loading = false
  
      if (!this.form.invalid) {
    
  
        this.tran.uploadNFe(formValues).then((res: any) => {
          console.log(res)
          this.closeModal()

          if (res.status) {
            this.getItems()

          } else {
            this.helper.triggerNotification(false)
          }
        }).catch(e => console.log(e))
        
        
      }
    }
  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.form.get('danfe').setValue(file);
    }
  }


  toggleModal(id){
    this.setupForm(id)
  }
  setupForm(id = null) {

    var obj = {
      "transaction_id": id,
      "danfe": [null, Validators.required],
    }
    this.form = this.formBuilder.group(obj)
  }


}
