import { EmptyStateComponent } from './components/empty-state/empty-state.component';
import { FlatpickrModule } from 'angularx-flatpickr';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxMaskModule } from 'ngx-mask';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { FlickityModule } from 'ngx-flickity';
import { AppRoutingModule } from './routes/app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { CategoriesComponent } from './pages/categories/categories.component';
import { HttpClientModule } from '@angular/common/http';
import { HotelsComponent } from './pages/hotels/hotels.component';
import { HotelsDetailComponent } from './pages/hotels/hotels-detail/hotels-detail.component';
import { PackagesComponent } from './pages/packages/packages.component';
import { PackagesDetailComponent } from './pages/packages/packages-detail/packages-detail.component';
import { OrdersComponent } from './pages/orders/orders.component';
import { OrdersDetailComponent } from './pages/orders/orders-detail/orders-detail.component';
import { LoginComponent } from './pages/login/login.component';
import localePt from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { CurrencyMaskModule, CurrencyMaskConfig, CURRENCY_MASK_CONFIG } from "ng2-currency-mask";
import { HotelsAddComponent } from './pages/hotels/hotels-add/hotels-add.component';
import { PackagesAddComponent } from './pages/packages/packages-add/packages-add.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { LoadingComponent } from './components/loading/loading.component';
import { AdminsComponent } from './pages/admins/admins.component';
import { AdminsAddComponent } from './pages/admins/admins-add/admins-add.component';
import { ClientsComponent } from './pages/clients/clients.component';
import { ClientsDetailComponent } from './pages/clients/clients-detail/clients-detail.component';
import { ClientsAddComponent } from './pages/clients/clients-add/clients-add.component';
import { ProjectsComponent } from './pages/projects/projects.component';
import { ProjectsAddComponent } from './pages/projects/projects-add/projects-add.component';
import { ProjectsDetailComponent } from './pages/projects/projects-detail/projects-detail.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { FinancialComponent } from './pages/financial/financial.component';
import { LeadsReportComponent } from './pages/marketing/leads-report/leads-report.component';

registerLocaleData(localePt);

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: "left",
  allowNegative: true,
  decimal: ",",
  precision: 2,
  prefix: "R$ ",
  suffix: "",
  thousands: "."
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavbarComponent,
    CategoriesComponent,
    HotelsComponent,
    HotelsDetailComponent,
    PackagesComponent,
    PackagesDetailComponent,
    OrdersComponent,
    OrdersDetailComponent,
    LoginComponent,
    HotelsAddComponent,
    PackagesAddComponent,
    BreadcrumbComponent,
    EmptyStateComponent,
    LoadingComponent,
    AdminsComponent,
    AdminsAddComponent,
    ClientsComponent,
    ClientsDetailComponent,
    ClientsAddComponent,
    ProjectsComponent,
    ProjectsAddComponent,
    ProjectsDetailComponent,
    SidebarComponent,
    FinancialComponent,
    LeadsReportComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    FlickityModule,
    NgxMaskModule.forRoot(),
    NgxDropzoneModule,
    FlatpickrModule.forRoot(),
    CurrencyMaskModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }