import { Injectable } from '@angular/core';
import { NetworkService } from './network.service';
import { Endpoints } from '../routes/endpoints';

@Injectable({
  providedIn: 'root'
})
export class TransactionsService {

  constructor(
    private network: NetworkService
  ) { }

  list(page = 1, q = '') {
    let url = `${Endpoints.Transactions}` + '?page='+page+ '&q=' + q
    return this.network.get(url)
  }


  adminList(page = 1, q = '') {
    let url = `${Endpoints.Users}` + '?type=admin&page='+page+ '&q=' + q
    return this.network.get(url)
  }

  show(id) {
    let url = `${Endpoints.Users}/${id}`
    return this.network.get(url)
  }

  save(params) {
    let id = params.id
    delete (params.id)
    if (id) {
      let url = `${Endpoints.Users}/${id}`
      return this.network.put(url, params)
    } else {
      let url = `${Endpoints.Users}`
      return this.network.post(url, params)
    }
  }
  


  uploadNFe(params) {
    let url = `${Endpoints.Transactions}/`+params.transaction_id+ `/danfe-upload` 
    return this.network.postMultipart(url, params)
  }
  sendPassword(id) {
    let url = `${Endpoints.ResendPassword}/${id}`
    return this.network.post(url,{})
  }

}
