import { Component, OnInit } from '@angular/core';
import { PathRoutes } from 'src/app/routes/path-routes';
import { SessionService } from 'src/app/auth/session.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  PathRoutes = PathRoutes
  user = null

  constructor(private session: SessionService) {

		this.user = this.session.getSession()

   }

  ngOnInit() {
  }

  logout(){
    this.session.destroySession();
  }

}
