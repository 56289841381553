import { Injectable } from '@angular/core';
import { Endpoints } from '../routes/endpoints';
import { NetworkService } from './network.service';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {

  constructor(
    private network: NetworkService
  ) { }

  list() {
    let url = `${Endpoints.Categories}`
    return this.network.get(url)
  }

  save(params) {
    let id = params.id
    delete (params.id)
    if (id) {
      let url = `${Endpoints.Categories}/${id}`
      return this.network.put(url, params)
    } else {
      let url = `${Endpoints.Categories}`
      return this.network.post(url, params)
    }
  }

  show(id) {
    let url = `${Endpoints.Categories}/${id}`
    return this.network.get(url)
  }
  
  delete(id) {
    let url = `${Endpoints.Categories}/${id}`
    return this.network.delete(url)
  }

}
