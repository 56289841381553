import { PathRoutes } from 'src/app/routes/path-routes';
import { Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/helpers/helper.service';
import { OrdersService } from 'src/app/services/orders.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-orders-detail',
  templateUrl: './orders-detail.component.html',
  styleUrls: ['./orders-detail.component.scss']
})
export class OrdersDetailComponent implements OnInit {
  id = null
  item: any
  PathRoutes = PathRoutes

  constructor(
    public helper: HelperService,
    private ordersService: OrdersService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.id = this.activatedRoute.snapshot.paramMap.get('id')
    this.getItem()
    console.log(this.item)
  }

  getItem() {
    this.ordersService.show(this.id).then((res: any) => {
      if(res.status){
        this.item = res.data
      }
    }).catch(e => console.log(e))
  }

  cancel(){
    this.helper.cancelSwal(false).then((result) => {
      if(result.value){
        this.helper.triggerNotification(true, 'Cancelado', 'Pedido cancelado com sucesso')
        this.getItem()
      }
    }).catch(e => console.log(e))
  }

}
