import { PathRoutes } from 'src/app/routes/path-routes';
import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/helpers/helper.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { FormValidatorsService } from 'src/app/services/form-validators.service';
import { ViewChild, ElementRef } from '@angular/core';
import { ProjectsService } from 'src/app/services/projects.service';

declare var $:any

@Component({
  selector: 'app-projects-detail',
  templateUrl: './projects-detail.component.html',
  styleUrls: ['./projects-detail.component.scss']
})
export class ProjectsDetailComponent implements OnInit {

  //form shit
  @ViewChild('closeBtn', {static: false}) closeBtn: ElementRef;
  form: FormGroup


  ////
  environment = environment
  PathRoutes = PathRoutes
  item: any
  id = null

  paymentStatus = [
    'pending',
    'paid'
  ];

  source = [
    'pagarme',
    'manual'
  ];

  method = [
    'credit_card',
    'boleto',
    'transfer',
    'deposit'
  ];

  tab = 'about'
  currentModal = ''

  loading = false;

  files = []
  
  constructor(
    public helper: HelperService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private projectsService: ProjectsService,

    private formValidators: FormValidatorsService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.getItem(this.id)
  }

  edit(i){

  }
  
  setupForm() {

    var obj;

    switch(this.currentModal){
      case 'addPayment':
        obj = {
          "project_id": this.id,
          "due_date": [null, Validators.required],
          "value": [null, Validators.required],
          "obs": [null],
          "method": ['credit_card', Validators.required],
          "source": ['pagarme', Validators.required]
        }
        break;

        case 'addRecurring':
          obj = {
            "project_id": this.id,
            "due_date": [null, Validators.required],
            "value": [null, Validators.required],
            "type": ['initial', Validators.required],
            "obs": [null],
            "method": ['credit_card', Validators.required],
            "source": ['pagarme', Validators.required]
          }
          break;

          case 'addFile':
            obj = {
              "project_id": this.id,
              "title": [null, Validators.required],
              "type": ['proposal', Validators.required],
              "file": [null, Validators.required],

              
            }
            break;

            case 'addComment':
              obj = {
                "project_id": this.id,
                "content": [null, Validators.required]
              
              }
              break;
              case 'addProduct':
                obj = {
                  "project_id": this.id,
                  "due_date": [null, Validators.required],
                  "start_date": [null, Validators.required],
                  "title": [null, Validators.required]
                }
                break;
              default:
                return;
    }
    this.form = this.formBuilder.group(obj)
  }

  changeTab(t){
    this.tab = t
  }

  toggleModal(t){
    this.currentModal = t
    this.setupForm()
  }

 
  saveModal() {
    this.loading = true
    let formValues = this.form.getRawValue()

    var apiCall;

    if (!this.form.invalid) {

    switch(this.currentModal){
      case 'addPayment':
        apiCall = this.projectsService.newPayment(formValues)
        this.loading = false

        break;
        case 'addRecurring':
        apiCall = this.projectsService.newRecurring(formValues)
        this.loading = false

        break;
        case 'addComment':
        apiCall = this.projectsService.newComment(formValues)
        this.loading = false

        break;
        case 'addFile':
        apiCall = this.projectsService.newFile(formValues)
        this.loading = false

        break;
        case 'addProduct':
        apiCall = this.projectsService.newProduct(formValues)
        this.loading = false

        break;
        default:
          apiCall = null
    }

    if(apiCall){
      apiCall.then((res: any) => {
        if (res.status) {
          this.getItem(this.id)
          this.closeModal()
        } else {
          this.helper.triggerNotification(false)
        }
      }).catch(e => console.log(e))
    }
      
    }
  }


  getItem(id) {
    this.projectsService.show(id).then((res: any) => {
      console.log({res})
      if(res.status){
        this.item = res.data

        this.item.files.map(f=>{
          if(f.path){
            var ext = f.path.split('.')
            ext = ext[1]
  
          } else {
            ext = null
          }
          f.ext = ext
          
        })
        this.item.payments.map(f=>{
          f.edit = false
        })
        this.item.recurring.map(r=>{
          r.edit = false
        })
        this.item.products.map(p=>{

          const _MS_PER_DAY = 1000 * 60 * 60 * 24;
          const from = new Date(p.start_date);
          const to = new Date(p.due_date);
          const today = new Date();
          const toUtc = Date.UTC(to.getFullYear(), to.getMonth(), to.getDate());
          const fromUtc = Date.UTC(from.getFullYear(), from.getMonth(), from.getDate());
          const todayUtc = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate());

          const diffDays = Math.floor((toUtc - fromUtc) / _MS_PER_DAY);
          const remainingTimeInDays = Math.floor((toUtc - todayUtc) / _MS_PER_DAY);

          const currentAt = diffDays - remainingTimeInDays

          p.progress =  Math.round((currentAt * 100) / diffDays)

          if(p.progress > 100) p.progress = 100
          if(p.progress < 0) p.progress = 0


        })
      }
    }).catch(e => console.log(e))
  }


  delete(id,type){
    this.helper.deleteSwal().then((result) => {
      if(result.value){
        this.projectsService.delete(id,type).then((res:any) => {
          if(res.status){
            this.helper.triggerNotification(true, 'Excluído', 'Excluído com sucesso')
            this.getItem(this.id)
            // this.router.navigate([PathRoutes.Packages.List])
          } else {
            this.helper.triggerNotification(false)
          }
        }).catch(e => console.log(e))
      }
    }).catch(e => console.log(e))
  }
   //call this wherever you want to close modal
   private closeModal(): void {
    this.closeBtn.nativeElement.click();
    } 



    onFileSelect(event) {
      if (event.target.files.length > 0) {
        const file = event.target.files[0];
        this.form.get('file').setValue(file);
      }
    }
  




}
