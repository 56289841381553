import Swal from 'sweetalert2';
import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup, ValidatorFn, AbstractControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  notificationBehavior = new BehaviorSubject<Notification>(null)
  notificationWatcher = this.notificationBehavior.asObservable()

  public masks = {
    mobile: "(00) 00000-0000",
    landline: "(00) 0000-0000",
    cep: "00000-000",
    cpf: "000.000.000-00",

    number: (qtd) => {
      var n = ''
      for (var i = 0; i < qtd; i++) {
        n += '0'
      }
      return n
    }
  }
  public ufs = [
    { "nome": "Acre", "sigla": "AC" },
    { "nome": "Alagoas", "sigla": "AL" },
    { "nome": "Amapá", "sigla": "AP" },
    { "nome": "Amazonas", "sigla": "AM" },
    { "nome": "Bahia", "sigla": "BA" },
    { "nome": "Ceará", "sigla": "CE" },
    { "nome": "Distrito Federal", "sigla": "DF" },
    { "nome": "Espírito Santo", "sigla": "ES" },
    { "nome": "Goiás", "sigla": "GO" },
    { "nome": "Maranhão", "sigla": "MA" },
    { "nome": "Mato Grosso", "sigla": "MT" },
    { "nome": "Mato Grosso do Sul", "sigla": "MS" },
    { "nome": "Minas Gerais", "sigla": "MG" },
    { "nome": "Pará", "sigla": "PA" },
    { "nome": "Paraíba", "sigla": "PB" },
    { "nome": "Paraná", "sigla": "PR" },
    { "nome": "Pernambuco", "sigla": "PE" },
    { "nome": "Piauí", "sigla": "PI" },
    { "nome": "Rio de Janeiro", "sigla": "RJ" },
    { "nome": "Rio Grande do Norte", "sigla": "RN" },
    { "nome": "Rio Grande do Sul", "sigla": "RS" },
    { "nome": "Rondônia", "sigla": "RO" },
    { "nome": "Roraima", "sigla": "RR" },
    { "nome": "Santa Catarina", "sigla": "SC" },
    { "nome": "São Paulo", "sigla": "SP" },
    { "nome": "Sergipe", "sigla": "SE" },
    { "nome": "Tocantins", "sigla": "TO" }

  ]

  public months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
  public weekDays = ['Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado', 'Domingo']

  constructor() { }

  triggerNotification(success, title = '', text = '', timer = 4000) {
    let notification: any = {}

    notification.title = title || (success ? 'Informações salvas' : 'Erro ao salvar')
    notification.text = text || (success ? 'As informações foram salvas com sucesso' : 'Ocorreu um erro ao salvar as informações')
    notification.icon = success ? 'success' : 'error'
    notification.showCloseButton = true
    notification.showConfirmButton = false
    notification.timer = timer

    Swal.fire(notification)
  }

  getFormatttedPhone(p){

      var ddd = p.substring(0, 2)
      var numero = p.substring(2, 100)

      var length = numero.length;
      var telefoneFormatado;
      
      if (length === 8) {
      telefoneFormatado = '(' + ddd + ') ' + numero.substring(0, 4) + '-' + numero.substring(4, 8);
      } else if (length === 9) {
      telefoneFormatado = '(' + ddd + ') ' + numero.substring(0, 5) + '-' + numero.substring(5, 9);
      } else {
        telefoneFormatado = p
      }
      return telefoneFormatado;
      
  }

  getFormattedPrice(price: number, cifrao = true) {
    var type = cifrao ? 'currency' : 'decimal'
    return new Intl.NumberFormat('pt-BR', { style: type, currency: 'BRL' }).format(price);
  }

  getStringDate(date) {
    var d = new Date(date)
    return `${this.weekDays[d.getDay() - 1]}, ${d.getDate()} de ${this.months[d.getMonth()]} de ${d.getFullYear()}`
  }

  flatpickrDefaultDateFormatter() {
    var date = new Date(),
      month = (date.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
      monthF = (month.length == 1) ? '0' + month : month,
      yearF = date.getFullYear();
    var dateInitial = (new Date(yearF, parseInt(month), 1 )).getDate().toString();
    var dateI = (dateInitial.length == 1) ? '0' + dateInitial : dateInitial
    var dateFinal = (new Date(yearF, parseInt(month), 0 )).getDate().toString();
    var dateF = (dateFinal.length == 1) ? '0' + dateFinal : dateFinal
    return `${dateI}/${monthF}/${yearF} até ${dateF}/${monthF}/${yearF}`
  }

  groupBy(collection, property) {
    var i = 0, val, index,
      values = [], result = [];
    for (; i < collection.length; i++) {
      val = collection[i][property];
      index = values.indexOf(val);
      if (index > -1)
        result[index].push(collection[i]);
      else {
        values.push(val);
        result.push([collection[i]]);
      }
    }

    return result.map(function (r, index) {
      return { ...r[0], qtd: r.length }
    })
  }

  customSwal(){
    return Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary ml-2',
        cancelButton: 'btn btn-outline-secondary btn-del mr-2'
      },
      buttonsStyling: false
    })
  }

  cancelSwal(active) {
    return this.customSwal().fire({
      title: 'Você tem certeza?',
      icon: 'warning',
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: active == 1 ? 'Sim, ativar!' : 'Sim, desativar',
      cancelButtonText: 'Cancelar'
    })
  }

  deleteSwal() {
    return this.customSwal().fire({
      title: 'Você tem certeza?',
      icon: 'warning',
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: 'Sim, excluir!',
      cancelButtonText: 'Cancelar'
    })
  }
  sendPWSwal() {
    return this.customSwal().fire({
      title: 'Você tem certeza? Você vai alterar a senha do usuário caso ele ainda tenha acesso.',
      icon: 'warning',
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: 'Sim, enviar!',
      cancelButtonText: 'Cancelar'
    })
  }


  formatTransactionStatus(s){
    switch(s){
      case 'paid':
        return 'Pago'
      case 'waiting_payment':
        return 'Aguardando'
      case 'refused':
        return 'Recusado'
      default:
        return s
    }
  }
  
}
